import React, { useEffect, useState } from 'react';
import InputField from '../Form/InputField';
import { useNextOfKin } from '../../Services/hooks/NextOfKin';
import { useSelector } from 'react-redux';
import { RootState } from '../../Storeredux/reducers/rootReducer';
import { userData } from '../../Storeredux/constants/actionTypes';
import CustomButton from '../Form/CustomButton';
import { areAllKeysFilled } from '../../Utils/formValidator';
import { AddCircle } from 'iconsax-react';

function NextOfKin() {
  const UserState = useSelector((state: RootState) => state.user as userData);
  const { user } = UserState;
  const { getNextOfKinData, postNextOfKinData, loading, nextOfKinData  } = useNextOfKin();
  console.log(nextOfKinData);
  const [readOnly, setReadOnly] = useState(true);
  const [nextOfKinExist, setNextOfKinExist] = useState(false);

  const [data, setData] = useState({
    name: nextOfKinData?.fullname,
    email: '',
    phone: '',
    relationship: '',
    location: '',
    user_id: user.id,
  });
  const [errorState, setErrorState] = useState({
    phone: '',
    bvn: '',
  });
  const isFormComplete = areAllKeysFilled(data);
  const validatePhoneNumber = (phoneNumber: string) => {
    const digitsOnly = phoneNumber.replace(/\D/g, '');
    const expectedLength = 11;
    if (digitsOnly.length === expectedLength) {
      setData({ ...data, phone: phoneNumber });
      setErrorState({ ...errorState, phone: '' });
    } else {
      setErrorState({ ...errorState, phone: 'Invalid Phone Number' });
    }
  };

  const onChange = (e: any, fieldKey: string) => {
    console.log('next', e.target.value, fieldKey);
    setData((prevData) => ({
      ...prevData,
      [fieldKey]: e.target.value,
    }));
    if (fieldKey === 'phone') {
      validatePhoneNumber(e.target.value);
      return;
    }

  };
  const submit = async (e: any) => {
    e.preventDefault();
    if (readOnly) {
      setReadOnly(true);
    } else {
      await postNextOfKinData(data);
      setReadOnly(true);
    }
  };

  console.log(nextOfKinData, 'nextOfKinData');
  useEffect(() => {
    getNextOfKinData(user.id);
  }, []);
  useEffect(() => {
    if (nextOfKinData) {
      setData({
        name: nextOfKinData.fullname || '',
        email: nextOfKinData.email || '',
        phone: nextOfKinData.phone || '',
        relationship: nextOfKinData.relationship || '',
        location: nextOfKinData.location || '',
        user_id: user.id,
      });
      setNextOfKinExist(true);

    } else {
      setNextOfKinExist(false);
    }
  }, [nextOfKinData, user.id]);
  return (
      <>
        {!nextOfKinExist ?
          (<div>
              <p className={'text-xl text-custom py-5'}>No Next Of kin Added</p>

              <button onClick={() => {
                setNextOfKinExist(!nextOfKinExist);
                setReadOnly(false);
              }}
                  className='flex gap-3.5 py-1.5 px-4 cursor-pointer border-0 rounded-custom items-center text-white text-sm font-semibold'
                  style={{ backgroundColor: '#20996B' }}>
                <AddCircle size="32" color="#ffffff" variant="Bold"/>
                Add Next Of Kin
              </button>
            </div>) :
          (<form onSubmit={submit} className='p-10 flex flex-col gap-10 h-5'>
              <div className='flex w-full gap-5'>
                <InputField name='Full Name' fieldKey={'name'} type='text' value={data.name} readOnly={readOnly}
                            onChange={onChange} className='w-full'/>
              </div>
              <div className='flex w-full gap-5'>
                <InputField name='Email Address' type='email' fieldKey={'email'} value={data.email} readOnly={readOnly}
                            onChange={onChange} className='w-full'/>
              </div>
              <div className='flex flex-col w-full gap-1'>
                <InputField name='Phone Number' type='tel' fieldKey={'phone'} value={data.phone} readOnly={readOnly}
                            onChange={onChange} className='w-full'/>
                {errorState.phone && (
                    <div className="text-red-500 text-xs mt-1">
                      {errorState.phone}
                    </div>
                )}
              </div>
              <div className='flex w-full gap-5'>
                <InputField name='Relationship' type='text' fieldKey={'relationship'} value={data.relationship}
                            readOnly={readOnly} onChange={onChange} className='w-full'/>
              </div>
              <div className='flex w-full gap-5'>
                <InputField name='Location' fieldKey={'location'} type='text' value={data.location} readOnly={readOnly}
                            onChange={onChange} className='w-full'/>
              </div>
              <div className='flex w-full gap-5'>
                {
                  readOnly ?
                      <button type={'button'} onClick={() => setReadOnly(false)}
                              className='bg-custom-midnightgreen text-white py-2 px-12 font-semibold rounded-custom border-0'>
                        Edit
                      </button> :
                      <CustomButton
                          isLoading={loading}
                          disabled={!isFormComplete}
                          children={'Save'}
                          className='bg-custom-midnightgreen text-white py-2 px-12 font-semibold rounded-custom border-0'
                      />
                }
              </div>

            </form>)
        }
      </>
  );
}

export default NextOfKin;
