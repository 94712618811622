import React, { useState } from 'react';
import JoinWaitList from './Joinwaitlist';
import { ReactComponent as HomeAbstract } from '../../Assets/Images/homeabstract.svg';
import { useNavigate } from 'react-router-dom';


function Homepage() {
  const [isOpen, setIsOpen] = useState(false);
  const Navigate = useNavigate();
  return (
    <div>
      <div className='relative'>
        <div className='w-full hidden md:flex'  >
          <HomeAbstract className='abstract'/>
        </div>
        <div className='relative md:absolute md:top-16 flex flex-col items-center justify-center w-full '>
          <div className='flex flex-col justify-center items-center w-10/12 xl:w-1/2 lg:w-1/2 mx-auto lg:py-0 py-8 text-center '>
            <h2 className=' font-bold text-5xl leading-none text-center'>
                            Easily Invest In Rental Homes <br/>&<br/> Off -Plan Real Estate
            </h2>
            <p className='text-xs font-semibold leading-5'>
                            Streamline Your Real Estate Investments: Embrace Rental Homes
                            and Off-Plan Opportunities.
            </p>
              <button onClick={() => Navigate('/register/co-own')}
                      className='rounded-custom text-white bg-custom-darkgreen border border-black font-semibold py-3.5 px-5 my-5'>
                  Get Started
              </button>
            <div className='w-full gap-4 flex mt-3.5'>
              {/*<input type='text' placeholder='Your Email' className='border-custom border w-7/12 rounded-custom text-sm py-3.5 px-2'/>*/}
              {/*<button onClick={() => setIsOpen(!isOpen)} className='rounded-custom text-white bg-custom-darkgreen border border-black font-semibold py-3.5 px-5'>*/}
              {/*                  Join WaitList*/}
              {/*</button>*/}
              {/*<div className={'w-full'}>*/}
              {/*  <Tabs tabs={tabs} activeTabIndex={activeTabIndex} onTabChange={handleTabChange}/>*/}
              {/*  <div className=" w-full">*/}
              {/*    <div className="">*/}
              {/*      {activeTabIndex === 0 ?*/}
              {/*          <div className={'bg-white rounded-b searchBoxShadow'}>*/}
              {/*            <div className={'flex gap-1 justify-around items-center p-4'}>*/}
              {/*              <div className={'w-[30%]'}>*/}
              {/*                <label className={'block text-start text-sm'}>Location</label>*/}
              {/*                <input type="text" placeholder={'Lagos,Nigeria'} className={'py-2 block w-full focus-visible:outline-0 md:text-sm text-xs'}/>*/}
              {/*              </div>*/}
              {/*              <span className={'h-7 bg-custom-grey w-[0.1%] mx-6'}></span>*/}
              {/*              <div className={'w-[30%]'}>*/}
              {/*                <label className={'block text-start text-sm'}>Property Type</label>*/}
              {/*                <div className="relative">*/}
              {/*                  <select*/}
              {/*                      className="appearance-none py-2 focus-visible:outline-0 rounded w-full pr-10 md:text-sm text-xs">*/}
              {/*                    <option value="" disabled selected>Select Property Type</option>*/}
              {/*                    <option>add property features</option>*/}
              {/*                    <option>Option 2</option>*/}
              {/*                    <option>Option 3</option>*/}
              {/*                  </select>*/}
              {/*                  <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center">*/}
              {/*                    <ArrowDown2*/}
              {/*                        size="20"*/}
              {/*                        color="#CED4DA"*/}
              {/*                        variant="Bold"*/}
              {/*                    />*/}
              {/*                  </div>*/}
              {/*                </div>*/}
              {/*              </div>*/}
              {/*              <span className={'h-7 bg-custom-grey w-[0.1%] mx-6'}></span>*/}
              {/*              <div className={'w-[30%]'}>*/}
              {/*                <button*/}
              {/*                    className='rounded-custom text-white bg-custom-darkgreen border font-semibold py-3.5 px-5'>*/}
              {/*                  Browse Properties*/}
              {/*                </button>*/}
              {/*              </div>*/}
              {/*            </div>*/}
              {/*          </div>*/}
              {/*        : activeTabIndex === 1 ?*/}
              {/*              <div className={'bg-white rounded-b searchBoxShadow'}>*/}
              {/*                <div className={'flex gap-1 justify-around items-center p-4'}>*/}
              {/*                  <div className={'w-[30%]'}>*/}
              {/*                    <label className={'block text-start text-sm'}>Location</label>*/}
              {/*                    <input type="text" placeholder={'Lagos,Nigeria'}*/}
              {/*                           className={'py-2 block w-full focus-visible:outline-0 md:text-sm text-xs'}/>*/}
              {/*                  </div>*/}
              {/*                  <span className={'h-7 bg-custom-grey w-[0.1%] mx-6'}></span>*/}
              {/*                  <div className={'w-[30%]'}>*/}
              {/*                    <label className={'block text-start text-sm'}>Property Type</label>*/}
              {/*                    <div className="relative">*/}
              {/*                      <select*/}
              {/*                          className="appearance-none py-2 focus-visible:outline-0 rounded w-full pr-10 md:text-sm text-xs">*/}
              {/*                        <option value="" disabled selected>Select Property Type</option>*/}
              {/*                        <option>add property features</option>*/}
              {/*                        <option>Option 2</option>*/}
              {/*                        <option>Option 3</option>*/}
              {/*                      </select>*/}
              {/*                      <div*/}
              {/*                          className="pointer-events-none absolute inset-y-0 right-0 flex items-center">*/}
              {/*                        <ArrowDown2*/}
              {/*                            size="20"*/}
              {/*                            color="#CED4DA"*/}
              {/*                            variant="Bold"*/}
              {/*                        />*/}
              {/*                      </div>*/}
              {/*                    </div>*/}
              {/*                  </div>*/}
              {/*                  <span className={'h-7 bg-custom-grey w-[0.1%] mx-6'}></span>*/}
              {/*                  <div className={'w-[30%]'}>*/}
              {/*                    <button*/}
              {/*                        className='rounded-custom text-white bg-custom-darkgreen border font-semibold py-3.5 px-5'>*/}
              {/*                      Browse Properties*/}
              {/*                    </button>*/}
              {/*                  </div>*/}
              {/*                </div>*/}
              {/*              </div>*/}
              {/*          : activeTabIndex === 2 ?*/}
              {/*                  <div className={'bg-white rounded-b searchBoxShadow'}>*/}
              {/*                    <div className={'flex gap-1 justify-around items-center p-4'}>*/}
              {/*                      <div className={'w-[30%]'}>*/}
              {/*                        <label className={'block text-start text-sm'}>Location</label>*/}
              {/*                        <input type="text" placeholder={'Lagos,Nigeria'}*/}
              {/*                               className={'py-2 block w-full focus-visible:outline-0 md:text-sm text-xs'}/>*/}
              {/*                      </div>*/}
              {/*                      <span className={'h-7 bg-custom-grey w-[0.1%] mx-6'}></span>*/}
              {/*                      <div className={'w-[30%]'}>*/}
              {/*                        <label className={'block text-start text-sm'}>Property Type</label>*/}
              {/*                        <div className="relative">*/}
              {/*                          <select*/}
              {/*                              className="appearance-none py-2 focus-visible:outline-0 rounded w-full pr-10 md:text-sm text-xs">*/}
              {/*                            <option value="" disabled selected>Select Property Type</option>*/}
              {/*                            <option>add property features</option>*/}
              {/*                            <option>Option 2</option>*/}
              {/*                            <option>Option 3</option>*/}
              {/*                          </select>*/}
              {/*                          <div*/}
              {/*                              className="pointer-events-none absolute inset-y-0 right-0 flex items-center">*/}
              {/*                            <ArrowDown2*/}
              {/*                                size="20"*/}
              {/*                                color="#CED4DA"*/}
              {/*                                variant="Bold"*/}
              {/*                            />*/}
              {/*                          </div>*/}
              {/*                        </div>*/}
              {/*                      </div>*/}
              {/*                      <span className={'h-7 bg-custom-grey w-[0.1%] mx-6'}></span>*/}
              {/*                      <div className={'w-[30%]'}>*/}
              {/*                        <button*/}
              {/*                            className='rounded-custom text-white bg-custom-darkgreen border font-semibold py-3.5 px-5'>*/}
              {/*                          Browse Properties*/}
              {/*                        </button>*/}
              {/*                      </div>*/}
              {/*                    </div>*/}
              {/*                  </div>*/}
              {/*            : null*/}
              {/*      }*/}
              {/*    </div>*/}
              {/*  </div>*/}
              {/*</div>*/}
            </div>
          </div>
        </div>
      </div>
      <JoinWaitList isOpen={isOpen} onHide={() => setIsOpen(false)}/>

    </div>
  );
}

export default Homepage;
