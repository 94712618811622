import React, { useEffect, useState } from 'react';
import { ReactComponent as UserIcon } from '../../../Assets/Icons/usernamicon.svg';
import { ReactComponent as CalenderIcon } from '../../../Assets/Icons/calenderIcon.svg';
import { ReactComponent as EmailIcon } from '../../../Assets/Icons/Emailiconwhite.svg';
import { ReactComponent as PasswordIcon } from '../../../Assets/Icons/passwordicon.svg';
import { ReactComponent as PhoneIcon } from '../../../Assets/Icons/phoneicon.svg';
import { Link, useNavigate } from 'react-router-dom';
import { ReactComponent as HomeAbstract } from '../../../Assets/Images/homeabstract.svg';
import { handleRegisterRequest, resetOtpState } from '../../../Storeredux/actions';
import { useDispatch, useSelector } from 'react-redux';
import CustomButton from '../../Form/CustomButton';
import { Auth } from '../../../Storeredux/constants/actionTypes';
import { RootState } from '../../../Storeredux/reducers/rootReducer';
import { Eye, EyeSlash } from 'iconsax-react';
import OtpVerification from '../../Login/OtpVerification';

function CoownSignup() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const AuthState = useSelector((state: RootState) => state.auth as Auth);
  const { loading, next } = AuthState;
  const [data, setData] = useState({
    'fname': '',
    'lname': '',
    'email': '',
    'password': '',
    'password_confirmation': '',
    'phone': '',
    'dob': '',
    'middle_name': '',
    // 'bvn': '',
  });
  const [errorState, setErrorState] = useState({
    phone: '',
    bvn: '',
  });
  const [passwordError, setPasswordError] = useState<string>('');
  const validatePhoneNumber = (phoneNumber: string) => {
    const digitsOnly = phoneNumber.replace(/\D/g, '');
    const expectedLength = 11;
    if (digitsOnly.length === expectedLength) {
      setData({ ...data, phone: phoneNumber });
      setErrorState({ ...errorState, phone: '' });
    } else {
      setErrorState({ ...errorState, phone: 'Invalid Phone Number' });
    }
  };
  const [isPasswordVisible, setPasswordVisible] = useState(false);
  const [isConfimPasswordVisible, setConfirmPasswordVisible] = useState(false);

  const togglePasswordVisibility = () => {
    setPasswordVisible(prevState => !prevState);
  };
  const toggleConfirmPasswordVisibility = () => {
    setConfirmPasswordVisible(prevState => !prevState);
  };
  const validateBvn = (bvn: string) => {
    const digitsOnly = bvn.replace(/\D/g, '');
    const expectedLength = 11;
    if (digitsOnly.length === expectedLength) {
      // setData({ ...data, bvn: bvn });
      setErrorState({ ...errorState, bvn: '' });
    } else {
      setErrorState({ ...errorState, bvn: 'Invalid Bvn Number' });
    }
  };
  const setOnChange = (e: any) => {
    if (e.target.name === 'password_confirmation') {
      if (e.target.value === data.password) {
        setData({ ...data, password_confirmation: e.target.value });
        setPasswordError('');
      } else {
        setData({ ...data, password_confirmation: '' });
        setPasswordError('Passwords do not match');
      }
      return;
    }
    if (e.target.name === 'phone') {
      validatePhoneNumber(e.target.value);
      return;
    }
    if (e.target.name === 'bvn') {
      validateBvn(e.target.value);
      return;
    }
    setData({ ...data, [e.target.name]: e.target.value });

  };
  const areAllKeysFilled = (obj: any) => {
    for (const key in obj) {
      // eslint-disable-next-line no-prototype-builtins
      const value = obj.hasOwnProperty(key);
      if (value) {
        if (!obj[key]) {
          return false;
        }
      }
    }
    return true;
  };
  const isFormComplete = areAllKeysFilled(data);
  const submitRegister = (e: any) => {
    e.preventDefault();
    console.log(data);
    dispatch(handleRegisterRequest({ payload: data, navigate: navigate }));
  };

  useEffect(() => {
    dispatch(resetOtpState());
  }, []);
  return (
    <div>
      <div className='relative overflow-hidden' style={{ height: '90vh' }}>
        <div className='w-full hidden md:flex'>
          <HomeAbstract className='abstract'/>
        </div>
        <div className=' absolute top-0 h-screen flex flex-col items-center w-full'
          style={{ backgroundColor: '#0000000D' }}>
          <div
            className={`${next ? 'hidden' : ''} bg-white rounded-xl md:h-3/4 xl:h-fit md:w-6/12 lg:w-5/12 xl:w-4/12 w-11/12 overflow-auto border border-input-color my-8`}
            style={{ boxShadow: '0px 2px 8px 2px #8C8C8C1A' }}>
            <form autoComplete="off" className='p-7 bg-white' onSubmit={submitRegister}>
              <div className='flex justify-between'><h3 className='font-semibold text-xl'>
                Register
              </h3>
              </div>
              <div className={'flex md:flex-row flex-col gap-2.5 pt-5'}>
                <div className='pb-3 w-full'>
                  <label
                      className='font-semibold text-sm pb-2'>
                    First Name
                  </label>
                  <div className="relative">
                    <UserIcon
                        className="absolute w-4 h-4 text-gray-500 left-2 top-1/2 transform -translate-y-1/2"/>
                    <input autoComplete='off' name='fname' onChange={(e) => {
                      if (/^[A-Za-z\s]*$/.test(e.target.value)) {
                        setOnChange(e);
                      } else {
                        e.target.value = e.target.value.replace(/[^A-Za-z\s]/g, '');
                      }
                    }} className="focus-visible:outline-0 text-xs w-full p-2 pl-10 border border-input-color rounded-custom"
                     type="text" placeholder="Enter your first name"  required/>
                  </div>
                </div>
                <div className='pb-3 w-full'>
                  <label
                      className='font-semibold text-sm pb-2'>
                    Last Name
                  </label>
                  <div className="relative">
                    <UserIcon
                        className="absolute w-4 h-4 text-gray-500 left-2 top-1/2 transform -translate-y-1/2"/>
                    <input autoComplete='off' name='lname' onChange={(e) => {
                      if (/^[A-Za-z\s]*$/.test(e.target.value)) {
                        setOnChange(e);
                      } else {
                        e.target.value = e.target.value.replace(/[^A-Za-z\s]/g, '');
                      }
                    }} required
                           className="focus-visible:outline-0 text-xs w-full p-2 pl-10 border border-input-color rounded-custom"
                           type="text" placeholder="Enter your last name"/>
                  </div>
                </div>
              </div>
              <div className={'flex md:flex-row flex-col gap-2.5'}>
                <div className='pb-3 w-full'>
                  <label
                      className='font-semibold text-sm pb-2'>
                    Middle Name
                  </label>
                  <div className="relative">
                    <UserIcon
                        className="absolute w-4 h-4 text-gray-500 left-2 top-1/2 transform -translate-y-1/2"/>
                    <input autoComplete='off' name='middle_name' onChange={(e) => {
                      if (/^[A-Za-z\s]*$/.test(e.target.value)) {
                        setOnChange(e);
                      } else {
                        e.target.value = e.target.value.replace(/[^A-Za-z\s]/g, '');
                      }
                    }} required
                           className="focus-visible:outline-0 text-xs w-full p-2 pl-10 border border-input-color rounded-custom"
                           type="text" placeholder="Enter your middle name"/>
                  </div>
                </div>
                <div className='pb-3 w-full'>
                  <label
                      className='font-semibold text-sm pb-2'>
                    Date of birth
                  </label>
                  <div className="relative">
                    <CalenderIcon
                        className="absolute w-4 h-4 text-gray-500 left-2 top-1/2 transform -translate-y-1/2"/>
                    <input autoComplete='off' name='dob' max={new Date().toISOString().split('T')[0]} onChange={setOnChange} required
                           className="focus-visible:outline-0 text-xs w-full text-deco p-1.5 pl-10 border border-input-color rounded-custom"
                           type="date" placeholder="Date of birth"/>
                  </div>
                </div>
              </div>
              <div className='pb-3'>
                <label className='font-semibold text-sm pb-2'>
                  Email
                </label>
                <div className="relative">
                  <EmailIcon
                      className="absolute w-4 h-4 text-gray-500 left-2 top-1/2 transform -translate-y-1/2"/>
                  <input autoComplete='off' name='email' onChange={setOnChange} required
                         className="focus-visible:outline-0 text-xs w-full p-2 pl-10 border border-input-color rounded-custom"
                         type="email" placeholder="Email"/>
                </div>
              </div>
              <div className='pb-3'>
                <label className='font-semibold text-sm pb-2'>
                  Phone Number
                </label>
                <div className="relative">
                  <PhoneIcon
                      className="absolute w-4 h-4 text-gray-500 left-2 top-1/2 transform -translate-y-1/2"/>
                  <input name='phone' onChange={setOnChange} required
                         className="focus-visible:outline-0 text-xs w-full p-2 pl-10 border border-input-color rounded-custom"
                         type="number" placeholder="Phone Number"/>
                </div>
                {errorState.phone && (
                    <div className="text-red-500 text-xs mt-1">
                      {errorState.phone}
                    </div>
                )}
              </div>
              {/*<div className='pb-3'>*/}
              {/*    <label className='font-semibold text-sm pb-2'>*/}
              {/*        BVN*/}
              {/*    </label>*/}
              {/*    <div className="relative">*/}
              {/*      <TableDocument className="absolute w-4 h-4 text-gray-500 left-2 top-1/2 transform -translate-y-1/2" size='4' />*/}
              {/*        <input name='bvn' onChange={setOnChange} required autoComplete='off'*/}
              {/*               className="focus-visible:outline-0 text-xs w-full p-2 pl-10 border border-input-color rounded-custom"*/}
              {/*               type="text" placeholder="Bvn"/>*/}
              {/*    </div>*/}
              {/*    {errorState.bvn && (*/}
              {/*        <div className="text-red-500 text-xs mt-1">*/}
              {/*          {errorState.bvn}*/}
              {/*        </div>*/}
              {/*    )}*/}
              {/*</div>*/}
              <div className='pb-3'>
                <label className='font-semibold text-sm pb-2'>
                  Password
                </label>
                <div className="relative">
                  <PasswordIcon
                      className="absolute w-4 h-4 text-gray-500 left-2 top-1/2 transform -translate-y-1/2"/>
                  <input name='password' onChange={setOnChange} required
                         className="focus-visible:outline-0 text-xs w-full p-2 pl-10 border border-input-color rounded-custom"
                         type={isPasswordVisible ? 'text' : 'password'} placeholder="Password"/>
                  <span
                      onClick={togglePasswordVisibility}
                      className="absolute right-2 top-1/2 transform -translate-y-1/2 cursor-pointer text-gray-500"
                  >
                    {isPasswordVisible ? <Eye size="20" color="#8E8E93"/> : <EyeSlash size="20" color="#8E8E93"/>}
                  </span>
                </div>
              </div>
              <div className='pb-3'>
                <label className='font-semibold text-sm pb-2'>
                  Confirm Password
                </label>
                <div className="relative">
                  <PasswordIcon
                      className="absolute w-4 h-4 text-gray-500 left-2 top-1/2 transform -translate-y-1/2"/>
                  <input name='password_confirmation' onChange={setOnChange} required
                         className="focus-visible:outline-0 text-xs w-full p-2 pl-10 border border-input-color rounded-custom"
                         type={isConfimPasswordVisible ? 'text' : 'password'}  placeholder="Password"/>
                  <span
                      onClick={toggleConfirmPasswordVisibility}
                      className="absolute right-2 top-1/2 transform -translate-y-1/2 cursor-pointer text-gray-500"
                  >
                    {isConfimPasswordVisible ? <Eye size="20" color="#8E8E93"/> : <EyeSlash size="20" color="#8E8E93"/>}
                  </span>
                </div>
                <div className='pb-3'>
                  {passwordError && <p className='text-red-500 text-xs italic'>{passwordError}</p>}
                </div>
              </div>
              <div className='flex justify-center'>
                <CustomButton
                    isLoading={loading}
                    children={'Register'}
                    className='w-6/12 bg-custom-darkgreen text-white font-semibold rounded-custom py-2'
                    disabled={!isFormComplete}
                />

              </div>
              <div className='text-center py-3 text-xs'>
                <p>Got an account? <Link to='/login'
                                         className='font-semibold text-custom-green'>Login</Link></p>
              </div>

            </form>
          </div>
          <OtpVerification email={data.email} next={next} />

        </div>
      </div>
    </div>
  );
}

export default CoownSignup;
