import { Link, useNavigate } from 'react-router-dom';
import React, { useState } from 'react';
import PinInput from '../Form/InputPin';
import { handleOtpRequest } from '../../Storeredux/actions';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../Storeredux/reducers/rootReducer';
import { Auth } from '../../Storeredux/constants/actionTypes';
import CustomButton from '../Form/CustomButton';
import { areAllKeysFilled } from '../../Utils/formValidator';

function OtpVerification({ next, email }: any) {
  const dispatch = useDispatch();
  const AuthState = useSelector((state: RootState) => state.auth as Auth);
  const { loading, email: hhhh } = AuthState;
  const navigate = useNavigate();
  const [error] = useState('');
  // const navigation = useNavigate();
  // const token = 'verified';
  const [data, setData] = useState('');
  const isFormComplete = areAllKeysFilled(data);
  console.log(email);


  const handleComplete = (pin: any) => {
    console.log('PIN entered:', pin);
    setData(pin);
  };

  const handleSubmit = () => {
    const payloadData = {
      'email': hhhh,
      'otp': data,
    };
    dispatch(handleOtpRequest({ payload: payloadData, navigate: navigate }));
  };

  return (
        <>
            <div
                className={`${next ? '' : 'hidden'} bg-white rounded-xl md:h-3/4 xl:h-fit md:w-6/12 lg:w-5/12 xl:w-4/12 w-11/12 overflow-auto border border-input-color my-8`}
                style={{ boxShadow: '0px 2px 8px 2px #8C8C8C1A' }}>

                <div className='p-7 bg-white'>
                    <div className='flex gap-1.5 items-center'>
                        <h3 className='font-semibold text-2xl'>
                            OTP Verification
                        </h3>
                    </div>
                    <div className='py-5'>
                        <p className={'text-sm text-green-500'}>
                            Enter the verification code we just sent to your email address
                        </p>
                    </div>
                    <div className='pb-1.5'>
                        <PinInput length={6} onComplete={handleComplete}/>
                        {error && <p className="text-red-600 text-xs pt-1.5">{error}</p>}
                    </div>
                    <p className='pb-5 text-end'><Link to='/login'
                             className='font-semibold text-sm text-custom-green'>Resend code</Link></p>
                    <div className='flex justify-center'>
                        <CustomButton
                            isLoading={loading}
                            disabled={!isFormComplete}
                            children={'Verify'}
                            onClick={handleSubmit}
                            className='text-sm md:text-md w-1/2 pt-3 mt-3.5 bg-custom-darkgreen text-white font-semibold rounded-custom py-2'
                        />
                    </div>
                    {/*<div className='text-center py-3 text-xs'>*/}
                    {/*    <p>Already have an account? <Link to='/login' onClick={() => dispatch(resetOtpState())}*/}
                    {/*                                      className='font-semibold text-custom-green'>Login</Link></p>*/}
                    {/*</div>*/}
                </div>
            </div>
        </>
  );
}

export default OtpVerification;
