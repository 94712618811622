import React from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import WaitlistImage from '../../Assets/Images/WaitlistImage.png';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';


interface Props {
  isOpen: boolean;
  onHide: () => void;
  images: [];
  selectedImage: number;
}

function ImageSlider({ isOpen, onHide, images, selectedImage }: Props) {
  const containerStyle: React.CSSProperties = {
    backgroundImage: `url(${WaitlistImage})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  };


  return (
        <Transition appear show={isOpen} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={onHide}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-black/90" style={containerStyle}/>
                </Transition.Child>

                <div className="fixed inset-0 overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <Dialog.Panel
                                className="w-full transform overflow-hidden border-wait-list border-4 lg:max-w-fit rounded-2xl bg-transparent relative text-left align-middle shadow-xl transition-all">
                                <button
                                    onClick={onHide}
                                    className='absolute top-4 right-4 z-50 text-black text-2xl font-bold bg-white rounded-full w-8 h-8 flex items-center justify-center shadow-md hover:bg-gray-200'
                                >
                                    &times;
                                </button>
                                <Swiper
                                    modules={[Navigation, Pagination]}
                                    navigation
                                    pagination={{ clickable: true }}
                                    initialSlide={selectedImage}
                                    className='rounded-lg shadow-lg'
                                >
                                    {images.map((img: any, idx) => (
                                        <SwiperSlide key={idx} className={'!h-[85vh]'}>
                                            <img src={img.image} alt={`Slide ${idx + 1}`}
                                                 className='w-full max-h-[100%] rounded-lg'/>
                                        </SwiperSlide>
                                    ))}
                                </Swiper>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
  );
}

export default ImageSlider;
