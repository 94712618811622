import { unauthorized } from '../axios';
import { FORGOT_PASSWORD, LOGIN, REGISTER, REGISTER_VERIFY_OTP } from '../service-routes';
import { ForgotPassword, Login, Otp, Register } from '../constant/serviceType';


export const postLogin = (payload: Login) => {
  return unauthorized.post(LOGIN, payload);
};

export const postRegister = (payload: Register) => {
  return unauthorized.post(REGISTER, payload);
};

export const postVerifyOtp = (payload: Otp) => {
  return unauthorized.post(REGISTER_VERIFY_OTP, payload);
};

export const postForgottenPassword = (payload: ForgotPassword) => {
  return unauthorized.post(FORGOT_PASSWORD, payload);
};
