import { postRegister } from '../services/authservices';
import { Register } from '../constant/serviceType';
import { NavigateFunction } from 'react-router-dom';

export const registerUser = async (payload: Register, navigate: NavigateFunction) => {
  let userResponse = null;

  try {
    const response = await postRegister(payload);
    console.log('registerUser', response);
    userResponse = response?.data?.data;
    if (userResponse) {
      console.log(navigate);
      // eslint-disable-next-line @typescript-eslint/naming-convention
      const { access_token } = userResponse;
      // navigate('/login');
      return { success: true, data: access_token, user: userResponse };
    }
  } catch (e: any) {
    // setError(e.response.data.errors);
    // console.log('kddk', e.response.data.errors.email || e.response.data.errors.phone || e.response.data.errors.password );
    // console.log(e.response.data);
    const allErrors = e.response?.data?.errors;
    const firstError = allErrors ? Object.values(allErrors).find(error => error) : e.response.data.message;
    console.log('kddk', allErrors);
    return { success: false, error: firstError };
  }

  return userResponse;
};
