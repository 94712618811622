import React, { Fragment, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { ReactComponent as CancelIcon } from '../../Assets/Icons/cancel.svg';
import { TableDocument } from 'iconsax-react';
import CustomButton from '../Form/CustomButton';
import { useUserData } from '../../Services/hooks/User';
import { useSelector } from 'react-redux';
import { userData } from '../../Storeredux/constants/actionTypes';
import { RootState } from '../../Storeredux/reducers/rootReducer';

interface Props {
  isOpen: boolean;
  onHide: () => void;
}

function UpdateBvn({ isOpen, onHide }: Props) {
  const [data, setData] = useState({
    bvn: '',

  });
  const [errorState, setErrorState] = useState({
    bvn: '',
  });
  const UserState = useSelector((state: RootState) => state.user as userData);
  const { user } = UserState;
  const { verifyBvnNumber, loading  } = useUserData();
  const validateBvn = (bvn: string) => {
    const digitsOnly = bvn.replace(/\D/g, '');
    const expectedLength = 11;
    if (digitsOnly.length === expectedLength) {
      setData({ ...data, bvn: bvn });
      setErrorState({ ...errorState, bvn: '' });
    } else {
      setErrorState({ ...errorState, bvn: 'Invalid Bvn Number' });
    }
  };

  const areAllKeysFilled = (obj: any) => {
    for (const key in obj) {
      // eslint-disable-next-line no-prototype-builtins
      const value = obj.hasOwnProperty(key);
      if (value) {
        if (!obj[key]) {
          return false;
        }
      }
    }
    return true;
  };
  const setOnChange = (e: any) => {
    if (e.target.name === 'bvn') {
      validateBvn(e.target.value);
      return;
    }
    setData({ ...data, [e.target.name]: e.target.value });

  };
  const isFormComplete = areAllKeysFilled(data);

  // useEffect(() => {
  //   const payload = {
  //     first_name: 'Habeeb',
  //     last_name: 'Sunmonu',
  //     maiden_name: 'Olanrewaju',
  //     gender: 'male',
  //     in_diaspora: 0, // 1 for true, 0 for false
  //     dob: '1995-12-22', // Format: YYYY-MM-DD
  //     country_code: 234,
  //     phone: '08131504250',
  //     email: 'habeebswiss@gmail.com',
  //   };
  //   updateUserData(payload, user.id);
  // }, []);
  const onSubmit = async (e: any) => {
    e.preventDefault();
    const payload = {
      user_id: user.id,
      bvn: data.bvn,
    };
    const { success } = await verifyBvnNumber(payload);
    if (success) {
      onHide();
    }

  };
  return (
        <Transition appear show={isOpen} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={onHide}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-black/25"/>
                </Transition.Child>

                <div className="fixed inset-0 overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <Dialog.Panel
                                className="w-full transform overflow-hidden lg:max-w-[55%] xl:max-w-[40%] rounded-2xl bg-white text-left align-middle shadow-xl transition-all">
                                <div className='flex justify-end px-4 py-4'>
                                    <CancelIcon onClick={onHide} className='cursor-pointer'/>
                                </div>
                                <div className='justify-center p-14 pt-7'>
                                    <h1 className={'font-bold text-2xl pb-3'}>Verify Identity</h1>
                                    <form className={'w-full'} onSubmit={onSubmit}>
                                        <div className='pb-3'>
                                            <label className='font-semibold text-sm pb-2'>
                                                BVN
                                            </label>
                                            <div className="relative">
                                              <TableDocument className="absolute w-4 h-4 text-gray-500 left-2 top-1/2 transform -translate-y-1/2" size='4' />
                                                <input name='bvn' onChange={setOnChange} required autoComplete='off'
                                                       className="focus-visible:outline-0 text-xs w-full p-2 pl-10 border border-input-color rounded-custom"
                                                       type="text" placeholder="Bvn"/>
                                            </div>
                                            {errorState.bvn && (
                                                <div className="text-red-500 text-xs mt-1">
                                                  {errorState.bvn}
                                                </div>
                                            )}
                                        </div>
                                        <CustomButton
                                            isLoading={loading}
                                            children={'Verify Bvn'}
                                            className='w-6/12 bg-custom-darkgreen mx-auto text-white font-semibold rounded-custom py-2'
                                            disabled={!isFormComplete}
                                        />
                                    </form>
                                </div>

                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
  );
}

export default UpdateBvn;
