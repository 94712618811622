import React, { useState } from 'react';
import { ReactComponent as EmailIcon } from '../../Assets/Icons/Emailiconwhite.svg';
import { Link } from 'react-router-dom';
import { ReactComponent as PasswordIcon } from '../../Assets/Icons/passwordicon.svg';
import { ReactComponent as HomeAbstract } from '../../Assets/Images/homeabstract.svg';
// import { useSelector } from 'react-redux';
// import { handleForgotPassword } from '../../Storeredux/actions';
import CustomButton from '../Form/CustomButton';
// import { RootState } from '../../Storeredux/reducers/rootReducer';
// import { Auth } from '../../Storeredux/constants/actionTypes';
import ForgotPasswordOtp from './forgotPassword';
// import forgotPassword from './forgotPassword';
import { forgotPasswordMethod } from '../../Services/hooks/ForgotPassword';
import { usePassword } from '../../Services/hooks/password';
import { areAllKeysFilled } from '../../Utils/formValidator';
import { useToast } from '../../Store/NotificationContext';

function ForgotPasswordForm() {
  const [next, setNext] = useState('forgetPassword');
  const { showToast } = useToast();
  const [data, setData] = useState({
    email: '',
  });
  const [resetData, setResetData] = useState({
    password: '',
    password_confirmation: '',
  });
  console.log(next);
  const { verifyPasswordOtp, isLoading: loading, resetPassword } = usePassword();
  const [isLoading, setIsLoading] = useState(false);
  const [pin, setPin] = useState('');
  const [error, setError] = useState('');
  const isFormComplete = areAllKeysFilled(resetData);

  const handlePasswordChange = (e: { target: { name: string; value: string; }; }) => {
    const { name, value } = e.target;
    setResetData((prevData) => ({ ...prevData, [name]: value }));

    // Check if passwords match in real-time
    if (name === 'password') {
      if (resetData.password_confirmation && value !== resetData.password_confirmation) {
        setError('Passwords do not match.');
      } else {
        setError(''); // Clear error if they match
      }
    } else if (name === 'password_confirmation') {
      if (resetData.password && resetData.password !== value) {
        setError('Passwords do not match.');
      } else {
        setError(''); // Clear error if they match
      }
    }
  };


  const verify = async () => {
    const payload = {
      email: data.email,
      otp: pin,
    };
    const response = await verifyPasswordOtp(payload);
    console.log(response);
    if (response) {
      setNext('resetPassword');
    }
  };

  const reset = async () => {
    const payload = {
      email: data.email,
      otp: pin,
      password: resetData.password,
      password_confirmation: resetData.password_confirmation,
    };
    const response = await resetPassword(payload);
    console.log(response);
    if (response) {
      setNext('resetPassword');
    }
  };

  const GetPin = (code: string) => {
    setPin(code);
  };

  const submit = async (e: { preventDefault: () => void; }) => {
    e.preventDefault();
    console.log(data);
    setIsLoading(true);
    const response = await forgotPasswordMethod(data);
    if (response.success) {
      setIsLoading(false);
      setNext('otp');
      showToast('Success', 'Otp sent successful', 'success');

    } else {
      setIsLoading(false);
      showToast('Error', response.error, 'error');
    }
    console.log(response);
    // dispatch(handleForgotPassword({ ForgotPassword: data, navigate: getNext }));
  };
  return (
    <div>
      <div className='relative overflow-hidden' style={{ height: '90vh' }}>
        <div className='w-full hidden md:flex'>
          <HomeAbstract className='abstract'/>
        </div>
        <div className='absolute top-0 h-screen flex flex-col items-center w-full' style={{ backgroundColor: '#0000000D' }}>
          {next === 'forgetPassword' ? (
                  <>
                    <div
                        className={' bg-white rounded-xl md:w-6/12 lg:w-5/12 xl:w-4/12 overflow-hidden border border-input-color my-12'}
                        style={{ boxShadow: '0px 2px 8px 2px #8C8C8C1A' }}>
                      <form className='w-full p-7' onSubmit={submit}>
                        <div className=''>
                          <h3 className='font-semibold text-xl'>
                            Relax, we have you covered.
                          </h3>
                          <p className='text-custom-darkgreen text-xs py-2'>Provide your email address to reset the
                            password.</p>
                        </div>
                        <div className='pt-6 pb-4'>
                          <label className='font-semibold text-sm'>
                            Email
                          </label>
                          <div className="relative">
                            <EmailIcon
                                className="absolute w-6 h-6 text-gray-500 left-2 top-1/2 transform -translate-y-1/2"/>
                            <input required onChange={(e) => setData({ ...data, email: e.target.value })}
                                   className="focus-visible:outline-0 w-full p-3 pl-10 border border-input-color rounded-custom"
                                   type="text" placeholder="Enter your email"/>
                          </div>
                        </div>
                        <CustomButton
                            isLoading={isLoading}
                            children={'Send password reset email'}
                            className='text-sm md:text-md w-full pt-3 mt-3.5 bg-custom-darkgreen text-white font-semibold rounded-custom py-2'
                            disabled={!data.email}
                        />
                        <div className='text-center py-3 text-sm'>
                          <p>
                            Remember account password?
                            <Link to='/login' className='font-semibold text-custom-green'>Login</Link>
                          </p>
                        </div>
                      </form>
                    </div>
                  </>
          ) : next === 'otp' ?
            (
                  <ForgotPasswordOtp next={'otp'} getPin={GetPin} submit={verify} isLoading={loading}/>
            ) :
            (
                <div
                className={' bg-white rounded-xl md:w-6/12 lg:w-5/12 xl:w-4/12 overflow-hidden border border-input-color my-12'}
                style={{ boxShadow: '0px 2px 8px 2px #8C8C8C1A' }}>
                  <div className='w-full p-7' >
                <div className=''>
                  <h3 className='font-semibold text-xl'>
                    Reset Password
                  </h3>
                  <p className='text-custom-darkgreen text-xs py-2'>Enter your new password</p>
                </div>
                <div className='pt-6 pb-2'>
                  <label className='font-semibold text-sm'>
                    Enter New Password
                  </label>
                  <div className="relative">
                    <PasswordIcon
                        className="absolute w-4 h-4 text-gray-500 left-2 top-1/2 transform -translate-y-1/2"/>
                    <input name={'password'} required onChange={handlePasswordChange} value={resetData.password}
                           className="focus-visible:outline-0 w-full p-3 pl-10 border border-input-color rounded-custom"
                           type="password" placeholder="Enter new Password"/>
                  </div>
                </div>
                <div className='pt-2 pb-4'>
                  <label className='font-semibold text-sm'>
                    Confirm New Password
                  </label>
                  <div className="relative">
                    <PasswordIcon
                        className="absolute w-4 h-4 text-gray-500 left-2 top-1/2 transform -translate-y-1/2"/>
                    <input name={'password_confirmation'} required onChange={handlePasswordChange} value={resetData.password_confirmation}
                           className="focus-visible:outline-0 w-full p-3 pl-10 border border-input-color rounded-custom"
                           type="password" placeholder="Confirm new password"/>
                  </div>
                  {error && <p className="text-red-500 text-xs mt-1">{error}</p>}

                </div>

                <CustomButton
                    isLoading={loading}
                    children={'Reset Password'}
                    className='text-sm md:text-md w-full pt-3 mt-3.5 bg-custom-darkgreen text-white font-semibold rounded-custom py-2'
                    disabled={!isFormComplete}
                    onClick={reset}
                />
                <div className='text-center py-3 text-sm'>
                  <p>
                    Remember account password?
                    <Link to='/login' className='font-semibold text-custom-green'>Login</Link>
                  </p>
                </div>
              </div>
              </div>
            )

        }

      </div>
    </div>
</div>
  )
  ;
}

export default ForgotPasswordForm;
