import { call, put, takeLatest } from 'redux-saga/effects';
import {
  handleLoginRequest,
  handleLoginSuccess,
  handleRegisterRequest,
  handleRegisterSuccess,
  // handleLogout,
  handleFailure,
  handleLogout,
  handleLogoutRequest,
  handleSuccess,
  handleForgotPassword,
  handleSaveUserData,
  handleSaveWalletData, handleStart, handleOtpRequest, handleNextPage,
} from '../actions';
import {
  Login, Otp,
  Register,
  RequestForgotPassword,
} from '../../Services/constant/serviceType';
import { registerUser } from '../../Services/hooks/Register';
import { showToast } from '../constants/toastAction';
import { loginUser, verifyOtp } from '../../Services/hooks/Login';
import { NavigateFunction } from 'react-router-dom';
import { PayloadAction } from '@reduxjs/toolkit';
import { purgeStoredState } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { forgotPasswordMethod } from '../../Services/hooks/ForgotPassword';
import { getDashboardData } from '../../Services/hooks/Dashboard';
import { getWalletData } from '../../Services/hooks/wallet';


function* login(action: PayloadAction<{ payload: Login, navigate: NavigateFunction }>) {
  try {
    const { payload, navigate } = action.payload;
    const { success, data, error, user } = yield call(
      loginUser,
      payload,
      navigate,
    );
    console.log(success, data, error, user);

    if (success && user) {
      const email = user.email;
      const token = data;
      console.log(success);
      yield put(handleLoginSuccess({ token, email }));
      yield put(handleSaveUserData(user[0]));
      const id = user[0].id;
      console.log(id);
      const { success: success1 } = yield call(
        getDashboardData,
        user[0].id,
      );
      const { result: wallet } = yield call(
        getWalletData,
        user[0].id,
      );
      yield put(handleSaveWalletData(wallet));
      console.log('walletsaga', wallet, success1);
      // yield put(showToast('Success', 'Login successful!', 'success'));

    } else {
      console.log(error, 'klk');
      yield put(handleFailure(error));
      yield put(showToast('Error', error, 'error'));
    }
  } catch (error: any) {
    console.log(error);
    console.log(
      'Auth saga error:',
      error.response?.data.message || error.message,
    );
    yield put(
      handleFailure(error.response?.data?.message || error.message),
    );
  }
}

function* postVerifyOtp(action: PayloadAction<{ payload: Otp, navigate: NavigateFunction }>) {
  try {
    const { payload, navigate } = action.payload;
    const { success, data, error, user } = yield call(
      verifyOtp,
      payload,
      navigate,
    );
    console.log(success, data, error, user[0]);
    // const token = data;
    yield put(handleNextPage());
    yield put(handleSuccess());

    // yield put(handleSaveUserData(user[0]));
    if (success && user) {
      const id = user[0].id;
      console.log(id);
      const { success: success1 } = yield call(
        getDashboardData,
        user[0].id,
      );
      const { result: wallet } = yield call(
        getWalletData,
        user[0].id,
      );
      yield put(handleSaveWalletData(wallet));
      console.log('walletsaga', wallet, success1);
      yield put(showToast('Success', 'Login successful!', 'success'));

    } else {
      yield put(handleFailure(error));
      yield put(handleNextPage());
      yield put(showToast('Error', error, 'error'));
    }
    return success;
  } catch (error: any) {
    console.log(
      'Auth saga error:',
      error.response?.data.message || error.message,
    );
    yield put(
      handleFailure(error.response?.data?.message || error.message),
    );
  }
}


function* register(action: PayloadAction<{ payload: Register, navigate: NavigateFunction }>) {
  try {
    const { payload, navigate } = action.payload;
    const { success, data, error, user } = yield call(
      registerUser,
      payload,
      navigate,
    );
    console.log(success, data, error);
    if (success) {
      const token = data;
      const email = user.email;
      yield put(handleRegisterSuccess({ token }));
      yield put(handleSaveUserData(user));
      yield put(handleNextPage());
      yield put(handleLoginSuccess({ token, email }));
      yield put(showToast('Success', 'Registration successful!', 'success'));
    } else {
      yield put(handleFailure(error));
      yield put(showToast('Error', error, 'error'));
    }
  } catch (error: any) {
    console.log(
      'Auth saga error:',
      error.response?.data.message || error.message,
    );
    yield put(
      handleFailure(error.response?.data?.message || error.message),
    );
  }
}

function* forgotPassword(action: { payload: RequestForgotPassword }) {
  try {
    yield put(handleStart());
    const { success, data, error } = yield call(
      forgotPasswordMethod,
      action.payload.ForgotPassword,
    );
    console.log(success, data, error);
    if (success) {
      yield put(handleSuccess());
      yield put(showToast('Success', 'Registration successful!', 'success'));
    } else {
      yield put(handleFailure(error));
      yield put(showToast('Error', error, 'error'));
    }
    return success;
  } catch (error: any) {
    console.log(
      'Auth saga error:',
      error.response?.data.message || error.message,
    );
    yield put(
      handleFailure(error.response?.data?.message || error.message),
    );
  }
}

function* logout() {
  try {
    yield purgeStoredState({
      key: 'root',
      storage,
    });
    yield put(handleLogout());
  } catch (error) {
    console.error('Error deleting token', error);
  }
}

function* watchAuthActions() {
  yield takeLatest(handleLoginRequest, login);
  yield takeLatest(handleRegisterRequest, register);
  yield takeLatest(handleForgotPassword, forgotPassword);
  yield takeLatest(handleLogoutRequest, logout);
  yield takeLatest(handleOtpRequest, postVerifyOtp);
}
export default watchAuthActions;
