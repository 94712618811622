export function shortenSentence(sentence: string, wordLimit = 5) {
  // console.log(sentence);
  if (sentence) {
    const words = sentence.split(' ');
    return words.length > wordLimit
      ? words.slice(0, wordLimit).join(' ') + '...'
      : sentence;
  } else return 'null';

}
