export const REGISTER = '/auth/register';
export const LOGIN = '/auth/login';
export const FORGOT_PASSWORD = '/auth/forgot-password';
export const GET_DASHBOARD = '/dashboard';
export const GET_PROPERTY = '/property';
export const FILTER_PROPERTY = '/properties/filter';
export const WITHDRAW_FUND = '/account/withdraw';
export const NEXT_OF_KIN = '/next-of-kin';
export const USER_DATA = '/user';
export const GET_INVESTMENT_INFO = '/invest';
export const PAYMENT_REQUEST = '/invest/pay';
export const CONTACT_US = '/contact-us';
export const VERIFY_PAYMENT = '/invest/verify';
export const GET_HOME_PROPERTY = 'featured-properties';
export const GET_USER_PROPERTY = 'user/properties';
export const GET_WALLET = '/user/wallet';
export const GET_PAYMENT_HISTORY = '/user/transactions/wallet';
export const PAY_WITH_WALLET = '/invest/wallet/pay';
export const CHECK_PAYMENT = '/deposit';
export const VALIDATE_ACCOUNT = '/account/validate';
export const STORE_ACCOUNT = '/account/store';
export const GET_BANKS = '/account/banks';
export const GET_FEES = '/invest/fee';
export const GET_BENEFICIARIES = '/account/beneficiaries';
export const VERIFY_WITHDRAW = '/account/withdraw/verify';
export const CHANGE_PASSWORD = '/user/change-password';
export const RESET_PASSWORD = '/auth/reset-password';
export const VERIFY_OTP = '/auth/verify-otp';
export const REGISTER_VERIFY_OTP = '/auth/register/otp';
export const NEWSLETTER = '/subscribe';
export const VERIFY_BVN = '/auth/update/bvn';
